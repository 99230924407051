import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { getToken } from '../../utils';
import '../AdminPage.css';

const apiUrl = process.env.REACT_APP_API_URL;

const WithdrawInvestment = ({ investors, properties }) => {
    const navigate = useNavigate();
    const [cashWithdrawal, setCashWithdrawal] = useState({
        amount: '',
        date: '',
        investor_name: '',
        description: '',
    });
    const [investmentWithdrawal, setInvestmentWithdrawal] = useState({
        property_name: '',
        amount: '',
        date: '',
        investor_name: '',
    });
    const [maxCashWithdrawalAmount, setMaxCashWithdrawalAmount] = useState(0);
    const [maxInvestmentWithdrawalAmount, setMaxInvestmentWithdrawalAmount] = useState(0);

    const handleCashWithdrawalChange = (e) => {
        const { name, value } = e.target;
        setCashWithdrawal({
            ...cashWithdrawal,
            [name]: value
        });

        if (name === 'investor_name') {
            setMaxCashWithdrawalAmount(0);
            cashWithdrawal.amount = 0;
        }
    };

    const handleInvestmentWithdrawalChange = (e) => {
        const { name, value } = e.target;
        setInvestmentWithdrawal({
            ...investmentWithdrawal,
            [name]: value
        });

        if (name === 'investor_name' || name === 'property_name') {
            setMaxInvestmentWithdrawalAmount(0);
            investmentWithdrawal.amount = 0;
        }
    };

    useEffect(() => {
        const fetchMaxCashWithdrawalAmount = async () => {
            const { investor_name } = cashWithdrawal;
            if (investor_name) {
                try {
                    const token = getToken(navigate);

                    const response = await axios.get(`${apiUrl}/admin/get_max_cash_withdrawal`, {
                        headers: { Authorization: `Bearer ${token}` },
                        params: {
                            investor_name
                        }
                    });

                    setMaxCashWithdrawalAmount(response.data.max_cash_withdrawal || 0);
                    cashWithdrawal.amount = 0;
                } catch (error) {
                    console.error('Error fetching max cash withdrawal amount:', error);
                }
            }
        };

        fetchMaxCashWithdrawalAmount();
    }, [cashWithdrawal.investor_name, navigate]);

    useEffect(() => {
        const fetchMaxInvestmentWithdrawalAmount = async () => {
            const { investor_name, property_name } = investmentWithdrawal;
            if (investor_name && property_name) {
                try {
                    const token = getToken(navigate);

                    const response = await axios.get(`${apiUrl}/admin/get_max_investment_withdrawal`, {
                        headers: { Authorization: `Bearer ${token}` },
                        params: {
                            investor_name,
                            property_name
                        }
                    });

                    setMaxInvestmentWithdrawalAmount(response.data.max_investment_withdrawal || 0);
                    investmentWithdrawal.amount = 0;
                } catch (error) {
                    console.error('Error fetching max investment withdrawal amount:', error);
                }
            }
        };

        fetchMaxInvestmentWithdrawalAmount();
    }, [investmentWithdrawal.investor_name, investmentWithdrawal.property_name, navigate]);

    const handleCashWithdrawalSubmit = async (e) => {
        e.preventDefault();

        const { amount, date, investor_name, description } = cashWithdrawal;
        if (!amount || !date || !investor_name || !description) {
            console.error('Please fill in all fields.');
            return;
        }

        if (parseFloat(amount) > maxCashWithdrawalAmount) {
            console.error(`Amount cannot exceed the maximum withdrawal amount of ${maxCashWithdrawalAmount}`);
            return;
        }

        try {
            const token = getToken(navigate);

            await axios.post(
                `${apiUrl}/admin/record_cash_withdrawal`,
                cashWithdrawal,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            alert('Cash withdrawal recorded successfully');
            setCashWithdrawal({
                amount: '',
                date: '',
                investor_name: '',
                description: '',
            });
            setMaxCashWithdrawalAmount(0);
            cashWithdrawal.amount = 0;
        } catch (error) {
            console.error('Error recording withdrawal:', error);
        }
    };

    const handleInvestmentWithdrawalSubmit = async (e) => {
        e.preventDefault();

        const { property_name, amount, date, investor_name } = investmentWithdrawal;
        if (!property_name || !amount || !date || !investor_name) {
            console.error('Please fill in all fields.');
            return;
        }

        if (parseFloat(amount) > maxInvestmentWithdrawalAmount) {
            console.error(`Amount cannot exceed the maximum withdrawal amount of ${maxInvestmentWithdrawalAmount}`);
            return;
        }

        try {
            const token = getToken(navigate);

            await axios.post(
                `${apiUrl}/admin/record_investment_withdrawal`,
                investmentWithdrawal,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            alert('Investment withdrawal recorded successfully');
            setInvestmentWithdrawal({
                property_name: '',
                amount: '',
                date: '',
                investor_name: '',
            });
            setMaxInvestmentWithdrawalAmount(0);
            investmentWithdrawal.amount = 0;
        } catch (error) {
            console.error('Error recording withdrawal:', error);
        }
    };

    return (
        <>
            <Row className='text-font admin-row'>
                <Col md={12}>
                    <p className="lead fw-bold text-font">Withdraw from Cash</p>
                    <form onSubmit={handleCashWithdrawalSubmit}>
                        <div className="form-group mb-3">
                            <label className="mb-1" htmlFor="investor_name">Investor:</label>
                            <select
                                id="investor_name"
                                name="investor_name"
                                className="form-control text-font"
                                value={cashWithdrawal.investor_name}
                                onChange={handleCashWithdrawalChange}
                                required
                            >
                                <option value="">Select Investor</option>
                                {investors.map((investor_name, index) => (
                                    <option key={index} value={investor_name}>{investor_name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group mb-3">
                            <label className="mb-1" htmlFor="date">Date:</label>
                            <input
                                type="date"
                                id="date"
                                name="date"
                                className="form-control"
                                value={cashWithdrawal.date}
                                onChange={handleCashWithdrawalChange}
                                required
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label className="mb-1" htmlFor="description">Description:</label>
                            <input
                                type="text"
                                id="description"
                                name="description"
                                className="form-control"
                                value={cashWithdrawal.description}
                                onChange={handleCashWithdrawalChange}
                                required
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label className="mb-1" htmlFor="amount">Amount:</label>
                            <input
                                type="range"
                                id="amount"
                                name="amount"
                                className="form-range"
                                min="0"
                                max={maxCashWithdrawalAmount}
                                value={cashWithdrawal.amount}
                                onChange={handleCashWithdrawalChange}
                                disabled={!cashWithdrawal.investor_name}
                            />
                            <div className="amount-display">${cashWithdrawal.amount}</div>
                        </div>
                        <div className="text-center">
                            <button type="submit" className="btn btn-primary">Withdraw Cash</button>
                        </div>
                    </form>
                </Col>
            </Row>
            <Row className='text-font admin-row'>
                <Col md={12}>
                    <p className="lead fw-bold text-font">Withdraw from Investment</p>
                    <form onSubmit={handleInvestmentWithdrawalSubmit}>
                        <div className="form-group mb-3">
                            <label className="mb-1" htmlFor="investor_name">Investor:</label>
                            <select
                                id="investor_name"
                                name="investor_name"
                                className="form-control text-font"
                                value={investmentWithdrawal.investor_name}
                                onChange={handleInvestmentWithdrawalChange}
                                required
                            >
                                <option value="">Select Investor</option>
                                {investors.map((investor_name, index) => (
                                    <option key={index} value={investor_name}>{investor_name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group mb-3">
                            <label className="mb-1" htmlFor="property_name">Property:</label>
                            <select
                                id="property_name"
                                name="property_name"
                                className="form-control text-font"
                                value={investmentWithdrawal.property_name}
                                onChange={handleInvestmentWithdrawalChange}
                                required
                            >
                                <option value="">Select Property</option>
                                {properties.map((property_name, index) => (
                                    <option key={index} value={property_name}>{property_name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group mb-3">
                            <label className="mb-1" htmlFor="date">Date:</label>
                            <input
                                type="date"
                                id="date"
                                name="date"
                                className="form-control"
                                value={investmentWithdrawal.date}
                                onChange={handleInvestmentWithdrawalChange}
                                required
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label className="mb-1" htmlFor="amount">Amount:</label>
                            <input
                                type="range"
                                id="amount"
                                name="amount"
                                className="form-range"
                                min="0"
                                max={maxInvestmentWithdrawalAmount}
                                value={investmentWithdrawal.amount}
                                onChange={handleInvestmentWithdrawalChange}
                                disabled={!investmentWithdrawal.investor_name || !investmentWithdrawal.property_name}
                            />
                            <div className="amount-display">${investmentWithdrawal.amount}</div>
                        </div>
                        <div className="text-center">
                            <button type="submit" className="btn btn-primary">Withdraw investment</button>
                        </div>
                    </form>
                </Col>
            </Row>
        </>
    );
};

export default WithdrawInvestment;
